import { ExperimentsPlatformDef } from "@hoppscotch/common/platform/experiments"
import { def as platformAuth } from "./firebase/auth"
import * as E from "fp-ts/Either"
import { z } from "zod"

const GenerateRequestNameResponseSchema = z.object({
  request_name: z.string(),
})

const generateRequestName = async (
  requestInfo: string,
  namingStyle: string
) => {
  try {
    const res = await fetch(
      `${
        import.meta.env.VITE_BACKEND_API_URL
      }/ai-experiments/generate-request-name`,
      {
        method: "POST",
        body: JSON.stringify({
          request_info: requestInfo,
          naming_style: namingStyle,
        }),
        headers: {
          ...platformAuth.getBackendHeaders(),
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    const result = GenerateRequestNameResponseSchema.safeParse(data)

    if (!result.success) {
      return E.left("UNABLE_TO_PARSE_RESPONSE")
    }

    return E.right({
      request_name: result.data.request_name,
      trace_id: data.trace_id,
    })
  } catch (e) {
    return E.left("CANNOT_GENERATE_REQUEST_NAME")
  }
}

const ModifyRequestBodyResponseSchema = z.object({
  modify_body: z.any(),
  trace_id: z.string(),
})

const modifyRequestBody = async (requestBody: string, userPrompt: string) => {
  try {
    const res = await fetch(
      `${
        import.meta.env.VITE_BACKEND_API_URL
      }/ai-experiments/modify-request-body`,
      {
        method: "POST",
        body: JSON.stringify({
          request_body: requestBody,
          prompt: userPrompt,
        }),
        headers: {
          ...platformAuth.getBackendHeaders(),
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    const result = ModifyRequestBodyResponseSchema.safeParse(data)

    if (!result.success) {
      return E.left("UNABLE_TO_PARSE_RESPONSE")
    }

    const modifiedContent =
      typeof result.data.modify_body !== "string"
        ? JSON.stringify(result.data.modify_body, null, 2)
        : result.data.modify_body

    return E.right({
      modified_body: modifiedContent,
      trace_id: result.data.trace_id,
    })
  } catch (e) {
    return E.left("CANNOT_MODIFY_REQUEST_BODY")
  }
}

const submitFeedback = async (rating: -1 | 1, traceID: string) => {
  try {
    const res = await fetch(
      `${import.meta.env.VITE_BACKEND_API_URL}/ai-experiments/submit-feedback`,
      {
        method: "POST",
        body: JSON.stringify({
          score: rating,
          trace_id: traceID,
        }),
        headers: {
          ...platformAuth.getBackendHeaders(),
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    if (!data.success) {
      return E.left("CANNOT_SUBMIT_FEEDBACK")
    }

    return E.right(undefined)
  } catch (e) {
    return E.left("CANNOT_SUBMIT_FEEDBACK")
  }
}

const ModifyScriptResponseSchema = z.object({
  codeblock: z.string(),
  trace_id: z.string(),
})

const modifyPreRequestScript = async (
  requestInfo: string,
  userPrompt: string
) => {
  try {
    const res = await fetch(
      `${
        import.meta.env.VITE_BACKEND_API_URL
      }/ai-experiments/gen-pre-request-script`,
      {
        method: "POST",
        body: JSON.stringify({
          request_info: requestInfo,
          prompt: userPrompt,
        }),
        headers: {
          ...platformAuth.getBackendHeaders(),
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    const result = ModifyScriptResponseSchema.safeParse(data)

    if (!result.success) {
      return E.left("UNABLE_TO_PARSE_RESPONSE")
    }

    return E.right({
      modified_script: result.data.codeblock,
      trace_id: result.data.trace_id,
    })
  } catch (e) {
    return E.left("CANNOT_MODIFY_PRE_REQUEST_SCRIPT")
  }
}

const modifyTestScript = async (requestInfo: string, userPrompt: string) => {
  try {
    const res = await fetch(
      `${
        import.meta.env.VITE_BACKEND_API_URL
      }/ai-experiments/gen-post-request-test-script`,
      {
        method: "POST",
        body: JSON.stringify({
          request_info: requestInfo,
          prompt: userPrompt,
        }),
        headers: {
          ...platformAuth.getBackendHeaders(),
          "Content-Type": "application/json",
        },
      }
    )

    const data = await res.json()

    const result = ModifyScriptResponseSchema.safeParse(data)

    if (!result.success) {
      return E.left("UNABLE_TO_PARSE_RESPONSE")
    }

    return E.right({
      modified_script: result.data.codeblock,
      trace_id: result.data.trace_id,
    })
  } catch (e) {
    return E.left("CANNOT_MODIFY_TEST_SCRIPT")
  }
}

export const def: ExperimentsPlatformDef = {
  aiExperiments: {
    enableAIExperiments: true,
    generateRequestName,
    modifyRequestBody,
    submitFeedback,
    modifyPreRequestScript,
    modifyTestScript,
  },
}
